import axios from 'axios'

const PROYECT_URL = process.env.REACT_APP_DOMAIN + '/api/proyects/'

const config = {
    headers: {

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
        'Content-Type': 'application/json',

    }
}
//const configMultipart = { headers: { 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' } };
const configMultipart = { headers: { "Content-Type": "multipart/form-data" } };
//Login user
const sendProyect = async (proyectData) => {
    let fd = new FormData();
    fd.append('position', proyectData.position);
    fd.append('date', proyectData.date);
    fd.append('description', proyectData.description);
    fd.append('repository', proyectData.repository);
    fd.append('technology', proyectData.technology);
    fd.append('title', proyectData.title);
    fd.append('image', proyectData.image);
    fd.append('url', proyectData.url);
    fd.append('experiment', proyectData.experiment);
    const response = await axios.post(PROYECT_URL, proyectData, config);
    return response.data

}
const updateProyect = async (proyectData) => {
    let fd = new FormData();

    const formatDate = proyectData.date.split("T")[0];
    console.log("formatDate " + formatDate + " ProyectData " + proyectData.date);

    fd.append('position', proyectData.position);
    fd.append('date', formatDate);
    fd.append('description', proyectData.description);
    fd.append('repository', proyectData.repository);
    fd.append('technology', proyectData.technology);
    fd.append('title', proyectData.title);
    fd.append('image', proyectData.image);
    fd.append('url', proyectData.url);
    fd.append('experiment', proyectData.experiment);





    /*
        const response = await axios.put(PROYECT_URL + proyectData.id, {
            title: proyectData.title,
            position: proyectData.position,
            date: formatDate,
            description: proyectData.description,
            repository: proyectData.repository,
            technology: proyectData.technology,
            image: proyectData.image,
            url: proyectData.url,
            experiment: proyectData.experiment,
        }, config);
        */

    // const response = await axios.put(PROYECT_URL + proyectData.id, fd, configMultipart);
    const response = await axios.put(PROYECT_URL + proyectData.id, proyectData, config);
    console.log("getAllProyects " + JSON.stringify(response.data));
    return response.data
}
const deleteProyect = async (id) => {
    const res = await axios.delete(PROYECT_URL + id);
    return res.data;
}
const getProyects = async () => {
    console.log({ PROYECT_URL });
    const response = await axios.get(PROYECT_URL, config);
    console.log({ response: response.data })
    return response.data
}



const proyectService = {
    sendProyect,
    getProyects,
    updateProyect,
    deleteProyect
}
export default proyectService