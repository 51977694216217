import axios from 'axios'

const CONTACT_URL = process.env.REACT_APP_DOMAIN + '/api/contact/'

const config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',

        'Content-Type': 'application/json'
    }
}

//Login user
const sendContact = async (contactData) => {
    const response = await axios.post(CONTACT_URL, contactData, config);
    return response.data;

}

const getContacts = async () => {
    const response = await axios.get(CONTACT_URL, config);
    return response.data
}

const contactService = {
    sendContact,
    getContacts
}
export default contactService