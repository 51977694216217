import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import proyectService from './proyectService';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    loadedProyects: false,
    deletedProyects: false
}

export const sendProyect = createAsyncThunk('api/sendProyect', async (proyectData, thunkApi) => {
    try {
        return await proyectService.sendProyect(proyectData)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})
export const updateProyect = createAsyncThunk('api/updateProyect', async (proyectData, thunkApi) => {
    try {
        return await proyectService.updateProyect(proyectData)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})
export const deleteProyect = createAsyncThunk('api/deleteProyect', async (id, thunkApi) => {
    try {
        return await proyectService.deleteProyect(id)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})
export const getProyects = createAsyncThunk('api/getProyects', async (thunkApi) => {
    try {
        return await proyectService.getProyects()
    } catch (error) {
        // si existe algun error
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})

export const proyectSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.messageContact = ''
            state.proyects = null
            state.loadedProyects = false
            state.deletedProyects = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendProyect.pending, (state) => {
                state.isLoading = true
            })
            .addCase(sendProyect.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.messageContact = action.payload
            })
            .addCase(sendProyect.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.messageContact = action.payload
            })
            .addCase(getProyects.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
                state.loadedProyects = false
            })
            .addCase(getProyects.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.loadedProyects = true
                state.proyects = action.payload
            })
            .addCase(getProyects.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.loadedProyects = false
                state.proyects = action.payload
            })
            .addCase(updateProyect.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(updateProyect.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.proyects = action.payload
            })
            .addCase(updateProyect.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.proyects = action.payload
            })

            .addCase(deleteProyect.pending, (state) => {
                state.deletedProyects = false
            })
            .addCase(deleteProyect.fulfilled, (state, action) => {
                state.deletedProyects = false
                state.proyects = action.payload
            })
            .addCase(deleteProyect.rejected, (state, action) => {
                state.deletedProyects = true
                state.proyects = action.payload
            })
    },
})

export const { reset } = proyectSlice.actions
export default proyectSlice.reducer