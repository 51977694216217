import React, { useRef, useEffect } from 'react';

import '../../css/animation.css'

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
const About = (props, ref) => {
    const treeJs = useRef(null);
    const modelMessage = useRef(null);
    console.log("hello are you interested in this project? you can see how i did this project in my github account")
    useEffect(() => {

        // init
        if (treeJs.current.children.length == 0) {
            let example = new THREE.Object3D();
            const camera = new THREE.PerspectiveCamera(
                100,
                treeJs.current.clientWidth / treeJs.current.clientHeight,
                0.1,
                1000
            )
            camera.position.z = 0.35

            const scene = new THREE.Scene();

            const light = new THREE.SpotLight()
            light.position.set(5, 5, 5)
            scene.add(light)
            scene.background = new THREE.Color(0x82F5F3F4);
            let face;
            const loader = new GLTFLoader()
            loader.load(
                `${process.env.REACT_APP_DOMAIN}/api/files/model/cara.gltf`,
                function (gltf) {
                    face = gltf.scene.children[0];
                    face.material = new THREE.MeshNormalMaterial();

                    scene.add(gltf.scene);
                },
                // called while loading is progressing
                function (xhr) {

                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    modelMessage.current.innerHTML = "Loading my head " + (xhr.loaded / xhr.total * 100) + '% loaded';
                    if ((xhr.loaded / xhr.total * 100) === 100) {
                        modelMessage.current.style.display = "none";
                    }
                },
                // called when loading has errors
                function (error) {

                    console.error(error);

                }
            );
            const renderer = new THREE.WebGLRenderer({ antialias: true });
            renderer.setSize(treeJs.current.clientWidth, treeJs.current.clientHeight);
            //  renderer.setAnimationLoop(animation);
            treeJs.current.appendChild(renderer.domElement);

            // animation

            function animate() {
                requestAnimationFrame(animate);
                if (face !== undefined) {
                    face.rotation.x -= 0.004;
                    face.rotation.y += 0.004;
                }
                renderer.render(scene, camera);
            };
            animate();
        }




    }, []);
    return (
        <div className='component' ref={ref} id='about'>
            <div id='container-about'>
                <div id="about-info">
                    <div>
                        <h1 className='info-text-primario text-big-tile'>Hi i'm Raudel</h1>
                        <h1 className='info-text-primario text-big-tile'>Full-stack web developer</h1>
                        <h1 className='info-text-secundario text-xs-big'>Based in México</h1>
                        <h1 className='text-big-tile'>I create web solutions.</h1>
                        <a href="#contacto" >
                            <button className='boton-action text-xs-big btn-hover'>Let's talk</button>
                        </a>
                    </div>
                </div>
                <div>
                    <h3
                        id="modelMessage"
                        ref={modelMessage}>Cargando</h3>
                    <div
                        ref={treeJs}
                        id='about-profile'
                    >
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(About) 