import { useState, useEffect, useRef } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, loadUser } from '../features/auth/authSlice'
import Spinner from '../app/components/Spinner'


function Login() {


    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const { email, password } = formData;
    let flag = useRef(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //destrocuturar datos de state
    const { user, isLoading, isError, isSuccess, message, haveUser } = useSelector(
        (state) => state.auth
    )
    useEffect(() => {

        if (isError) {
            toast.error(message)
            navigate('/login')
        }

        if (isSuccess || user) {
            navigate('/proyects')
        }
        if ((localStorage.token && isLoading === false && haveUser === false && flag.current === false)) {

            flag.current = true;
            console.log("executa")
            dispatch(loadUser(localStorage.token));
        }



    }, [user, isError, isSuccess, isLoading, message, haveUser, navigate, dispatch])
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }
    if (isLoading) {
        return <Spinner />
    }
    const onSubmit = (e) => {
        e.preventDefault()
        const userData = {
            email,
            password
        }
        dispatch(login(userData))
    }

    return (
        <>
            <section className='heading'>
                <h1>
                    <FaSignInAlt /> Login
                </h1>
                <p>Please Login</p>
            </section>
            <section className='form'>
                <form onSubmit={onSubmit}>
                    <div className='form-group'>
                        <input type="email"
                            className='form-control'
                            id="email"
                            name='email'
                            value={email}
                            placeholder="enter your email"
                            onChange={onChange}>
                        </input>
                        <input type="password"
                            className='form-control'
                            id="password"
                            name='password'
                            value={password}
                            placeholder="enter your password"
                            onChange={onChange}>
                        </input>
                        <div className='form-group'>
                            <button type='submit' className='btn btn-block'>submit</button>
                        </div>
                    </div>
                </form>
            </section>
        </>
    )
}

export default Login