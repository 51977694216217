//import { useSelector } from 'react-redux'
import React, { useRef, useEffect, useState } from "react";

import About from '../app/components/About';
import AboutDetails from '../app/components/AboutDetails';
import Experiments from '../app/components/Experiments';
import Contact from '../app/components/Contact';
import Footer from '../app/components/Footer';
import Header from '../app/components/Header'
import '../css/DashBoard.scss';


function Dashboard() {
    const [activeClass, setaAtiveClass] = useState("nav-transparente");
    const containerRef = useRef();
    //let activeClass = false;
    const options = {
        rootMargin: "-900px 0px 0px 0px"
    };

    const observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // entry.target.classList.toggle("inverse");
                setaAtiveClass("nav-transparente");

            } else {
                setaAtiveClass("");

            }

        })
    }, options);

    useEffect(() => {
        if (containerRef.current) {
            observer.observe(containerRef.current);
            // <-- save ref value
        }
    }, []);
    // const { user } = useSelector((state) => state.auth)
    //{isVisible ? <h1>asdasd</h1> : }
    //ref={elementRef}
    return (
        <div className="mainDashBoard">
            <Header active={activeClass} />
            <About ref={containerRef} />
            <AboutDetails />
            <Experiments />
            <Contact />
            <Footer />
        </div>

    )
}

export default Dashboard