import React from 'react'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, } from '@fortawesome/free-brands-svg-icons';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

function ProyectCard(props) {
    const { technology, date, description, title, image, url, repository } = props;

    var formatDate = date.split('T')[0];

    const technologyItem = technology.map((item, index) => <label key={index}>{item}</label>);
    return (

        <div className='proyect-card'>
            <div className="images">
                <img
                    src={image} alt="No se pudo cargar"
                    loading="lazy"
                    width="500"
                    height="600"
                >
                </img>
            </div>
            <div className="title">
                {title}
            </div>
            <div className="description">
                {description}

            </div>
            <div className='proyect-technologys'>
                <div className='proyect-action'>
                    {url !== '' &&
                        <a href={url} target="_">
                            <button className='btn-visit-page'
                            >
                                Visitar página
                                <FontAwesomeIcon icon={faUpRightFromSquare}> </FontAwesomeIcon>
                            </button>
                        </a>

                    }
                    {repository !== '' &&
                        <a href={repository} target="_">
                            <button
                                className='btn-repository'
                            >
                                GITHUB
                                <FontAwesomeIcon icon={faGithub}> </FontAwesomeIcon>
                            </button>
                        </a>
                    }

                </div>
                <div className='technology'>
                    {technologyItem}
                </div>
                <div className="date">
                    {formatDate}
                </div>
            </div>
        </div>
    )
}

ProyectCard.propTypes = {
    //position: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    date: PropTypes.string,
    repository: PropTypes.string,
    technology: PropTypes.array
}
/*
<Link to={url}>Ver</Link>
<div className="date">
    date: {date}
</div>

*/

export default ProyectCard