import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import contactService from './contactService';

const initialState = {
    isError: false,
    isSucces: false,
    isLoading: false,
}

export const sendContact = createAsyncThunk('api/contact', async (contactData, thunkApi) => {
    try {
        return await contactService.sendContact(contactData)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})

export const getContacts = createAsyncThunk('api/getContacts', async (thunkApi) => {
    try {
        return await contactService.getContacts()
    } catch (error) {
        // si existe algun error
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.messageContact = ''
            state.contacts = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendContact.pending, (state) => {
                state.isLoading = true
            })
            .addCase(sendContact.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.messageContact = action.payload
            })
            .addCase(sendContact.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.messageContact = action.payload
            })
            .addCase(getContacts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getContacts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.contacts = action.payload
            })
            .addCase(getContacts.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.contacts = action.payload
            })
    },
})

export const { reset } = contactSlice.actions
export default contactSlice.reducer