import axios from 'axios'

const TEXT_TO_VOICE = process.env.REACT_APP_DOMAIN + '/api/voice/'

const config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',

        'Content-Type': 'application/json'
    }
}

//Login user
const sendText = async (text) => {
    const response = await axios.post(TEXT_TO_VOICE, { text }, config);
    console.log({ response: response.data });
    return response.data;
}


const voiceTextService = {
    sendText,
}
export default voiceTextService