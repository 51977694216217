import React, { useState, useEffect } from 'react'

//import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendTextToVoice } from '../features/voiceText/voiceTextSlice';
function Speech() {
    const [textAreaText, setTextAreaText] = useState("");
    console.log(`${process.env.REACT_APP_DOMAIN}/api/files/model/cara.gltf`)
    const dispatch = useDispatch()
    const { isLoading, isError, isSuccess, response } = useSelector(
        (state) => state.voiceText
    )
    const onSubmit = (e) => {
        e.preventDefault()
        console.log("Submit " + textAreaText);
        dispatch(sendTextToVoice(textAreaText))
    }
    const onChange = (e) => {
        setTextAreaText(e.target.value)

    }

    useEffect(() => {
        console.log({ response, isError, isSuccess, isLoading })
        if (isError && isSuccess === false) {

        }
        if (isLoading === true) {

            //  planeIcon.current.ClassName = "plane-animation-active";
        }
        if (isSuccess && isLoading === false) {

        }

    }, [response, isError, isSuccess, isLoading, dispatch])
    return (
        <>
            <form className="contact-form row" onSubmit={onSubmit} id='form-contact'>

                <div className="form-field col x-50">


                    <label htmlFor="story">Tell us your story:</label>

                    <textarea
                        id="text-area-voice"
                        placeholder="this text will show in the textarea"
                        name="story"
                        onChange={onChange}
                        rows="5" cols="33">

                    </textarea>
                </div>
                <button
                    /*
                        ref={refIcon}
                        disabled={currenState.isLoading}
                        */
                    type="submit" className="submit-btn font-primera btn-hover" value="Submit" >
                    <FontAwesomeIcon
                        icon={faPaperPlane}
                    > </FontAwesomeIcon><span> </span>
                    Submit
                </button>
            </form>
            <audio controls >
                <source src={`${process.env.REACT_APP_DOMAIN}/api/files/model/cara.gltf`} type="audio/wav">
                    Your browser does not support the audio element.
                </source>
            </audio>
        </>
    )
}

export default Speech