import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { sendProyect } from '../features/proyect/proyectSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { loadUser } from '../features/auth/authSlice'
import EditProyects from '../app/components/EditProyects'
function AddProyect() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let toogle = useRef(true);
    const [toogleRepository, setToogleRepository] = useState(false)
    const [fileInputState, setfileInputState] = useState('');
    const [selectedFileState, setSelectedFile] = useState('');
    const [previewSource, setPreviewSource] = useState('');
    const [toogleUrl, settoogleUrl] = useState(false)
    const [formData, setFormData] = useState({
        position: '',
        date: '',
        description: '',
        title: '',
        image: '',
        url: '',
        repository: '',
        technology: '',
        experiment: false
    });

    let flag = useRef(false);
    const { user, isLoading, isError, isSuccess, message, haveUser } = useSelector(
        (state) => state.auth
    )
    const proyectState = useSelector((state) => state.proyect)
    useEffect(() => {

        if (proyectState.isSuccess) {
            toast.success("Proyect added");
        }
        if (isSuccess) {

        }
        if (!localStorage.token) {
            navigate('/')
        }
        if ((localStorage.token && isLoading === false && haveUser === false && flag.current === false)) {

            flag.current = true;
            dispatch(loadUser(localStorage.token));
        }
    }, [toogleRepository, user, isError, isSuccess, isLoading, message, haveUser, proyectState, navigate, dispatch])


    const { position, date, description, title, image, url, repository, technology, experiment } = formData;
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

    }
    const onChangeTechnology = (e) => {
        let technology = e.target.value + "";
        let technologyList = technology.split(",");
        setFormData((prevState) => ({
            ...prevState,
            technology: technologyList
        }));
    }
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
            setFormData((prevState) => ({
                ...prevState,
                image: reader.result
            }));

        }
    }
    const onChangeFile = (e) => {
        const file = e.target.files[0];
        previewFile(file);

    }

    const onChangeCheckBox = (e) => {
        toogle.current = !toogle.current;
        setFormData((prevState) => ({
            ...prevState,
            experiment: !formData.experiment
        }));
    }
    const onChangeCheckRepository = (e) => {
        //toogleRepository = e.target.checked;
        setToogleRepository(e.target.checked);
    }
    const onChangeCheckUrl = (e) => {
        //toogleRepository = e.target.checked;
        settoogleUrl(e.target.checked);
    }

    const uploadImage64 = (image64) => {
        console.log("Image :" + image64)
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (!previewSource) return;
        uploadImage64(previewSource);
        const proyectData = {
            position,
            date,
            description,
            repository,
            technology,
            title,
            image,
            url,
            experiment
        }
        console.log(proyectData);
        dispatch(sendProyect(proyectData))

    }

    return (
        <div className='component' id='add-proyect'>
            <EditProyects />
            <section className='add-proyect'>
                <h1 className="title">Add proyect</h1>
                <form onSubmit={onSubmit}
                    className="contact-form row" id='form-add-proyect'
                    encType="multipart/form-data"
                >
                    <div className='form-group form-group-add-pryect'>
                        <div className="form-field col x-50 ">
                            <input type="title"
                                className={formData.title === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="title"
                                name='title'
                                value={title}
                                onChange={onChange}>
                            </input>
                            <label className="label" >Title</label>
                        </div>
                        <div className="form-field col x-50">
                            <input type="text"
                                className={formData.position === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="input-position"
                                name='position'
                                value={position}
                                onChange={onChange}>
                            </input>
                            <label className="label" >Position</label>
                        </div>
                        <div className="form-field col x-50">
                            <input type="text"
                                className={formData.technology === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="input-position"
                                name='position'
                                value={technology}
                                onChange={onChangeTechnology}>
                            </input>
                            <label className="label" >technology: a,b,c,t</label>
                        </div>

                        <div className="form-field col x-100">
                            <textarea
                                className={formData.description === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="description"
                                name="description"
                                value={description}
                                onChange={onChange}
                                rows="4"
                                cols="50">
                            </textarea>
                            <label className="label" >Description</label>
                        </div>
                        <div className="form-field col x-100">
                            <div className="form-field col x-10">
                                <label >Url</label>
                                <input type="checkbox"
                                    className='form-control'
                                    id="checkBoxRepository"
                                    name='checkBoxRepository'
                                    onChange={onChangeCheckUrl}
                                >
                                </input>
                            </div>
                            {toogleUrl &&
                                <>
                                    <input type="url"
                                        className={formData.url === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                        id="url"
                                        name='url'
                                        value={url}
                                        onChange={onChange}>
                                    </input>
                                    <label className="label" >Proyect Url</label>
                                </>
                            }
                        </div>
                        <div className="form-field col x-100">

                            <div className="form-field col x-10">
                                <label >Repository</label>
                                <input type="checkbox"
                                    className='form-control'
                                    id="checkBoxRepository"
                                    name='checkBoxRepository'
                                    onChange={onChangeCheckRepository}
                                >
                                </input>
                            </div>
                            {toogleRepository &&
                                <>
                                    <label className="label" >Repository Url</label>
                                    < input type="url"
                                        className={formData.repository === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                        id="repository"
                                        name='repository'
                                        value={repository}
                                        onChange={onChange}>
                                    </input>
                                </>

                            }


                        </div>
                        <div className="form-field col x-30">
                            <label >Date</label>
                            <input type="date"
                                className={formData.date === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="date"
                                name='date'
                                value={date}
                                placeholder="Fecha"
                                onChange={onChange}>

                            </input>
                        </div>
                        <div className="form-field col x-10">
                            <label >Experiment</label>
                            <input type="checkbox"
                                className='form-control'
                                id="experiment"
                                name='experiment'
                                onChange={onChangeCheckBox}
                                checked={toogle.current}
                            >
                            </input>
                        </div>
                        <div className="form-field col x-100">
                            <label >Imagen</label>
                            <input type="file"
                                accept="image/png, image/jpeg"
                                id="image"
                                name='image'
                                onChange={onChangeFile}
                                value={fileInputState}
                            >
                            </input>
                            {previewSource &&
                                <img
                                    src={previewSource}
                                    alt="chosen"
                                    style={{ height: '300px' }}
                                ></img>
                            }
                        </div>
                        <div className='form-field col x-100 align-center'>
                            <button className="submit-btn" type="submit" value="Submit">submit</button>
                        </div>
                    </div>
                </form>

            </section>
        </div >
    )
}

export default AddProyect