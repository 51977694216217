import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getProyects, updateProyect, deleteProyect } from '../../features/proyect/proyectSlice';

const onChange = (e) => {
    console.log(e.target.name + " , " + e.target.value);
}

function EditProyects() {
    const dispatch = useDispatch();
    //const [toogleRepository, setToogleRepository] = useState(false)
    const [toogleEdit, settoogleEdit] = useState([])
    let toogle = useRef(true);
    const [proyectData, setProyectData] = useState({
        position: 'nan',
        date: 'nan',
        description: 'nan',
        repository: 'nan',
        technology: 'nan',
        title: 'nan',
        id: '',
        image: 'nan',
        url: 'nan',
        experiment: true

    });
    const [previewSource, setPreviewSource] = useState('');
    const [fileInputState, setfileInputState] = useState('');
    const { /*user,  isError, isSuccess,isLoading*/ proyects, loadedProyects } = useSelector(
        (state) => state.proyect
    )
    const onDeleteProyect = (e, id) => {
        console.log("id :" + id);
        dispatch(deleteProyect(id));
    }
    const onUpdateProyect = (e, id) => {

        e.preventDefault();

        // console.log(e.target.getAttribute('key'));

        let technology = e.target.technology.value + "";
        let technologyList = technology.split(",");
        /*
                setProyectData(prevState => ({
                    ...prevState,
                    title: e.target.title.value,
                    position: e.target.position.value,
                    date: e.target.date.value,
                    description: e.target.description.value,
                    repository: e.target.repository.value,
                    technology: technologyList,
                    image: e.target.image.value,
                    url: e.target.url.value,
                    experiment: e.target.experiment.checked,
                    id
                }));
                */
        setProyectData(prevState => ({
            ...prevState,
            title: e.target.title.value,
            position: e.target.position.value,
            date: e.target.date.value,
            description: e.target.description.value,
            repository: e.target.repository.value,
            technology: technologyList,
            url: e.target.url.value,
            experiment: e.target.experiment.checked,
            id
        }));


    }
    /*
    const onChangeTechnology = (e) => {
        let technology = e.target.value + "";
        let technologyList = technology.split(",");
        setProyectData((prevState) => ({
            ...prevState,
            technology: technologyList
        }));
    }*/
    /*onChangeCheckUrl */
    const onChangeEdit = (e, index) => {
        const newToogleEdit = toogleEdit.map((element) => {
            if (element.id === index) {
                return {
                    ...element,
                    toogle: !element.toogle
                }

            }
            return element
        });
        settoogleEdit(newToogleEdit);
        //toogleRepository = e.target.checked;
        /*
             settoogleEdit((prevCheck) => !prevCheck)
             console.log(e.target.nextElementSibling.classList);
             e.target.nextElementSibling.classList.add("mystyle");
     
         */
    }
    /*
    const onChangeCheckRepository = (e) => {
        //toogleRepository = e.target.checked;
        setToogleRepository(e.target.checked);
    }
    */
    const onChangeCheckBox = (e) => {
        toogle.current = !toogle.current;
        setProyectData((prevState) => ({
            ...prevState,
            experiment: !proyectData.experiment
        }));
    }
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
            setProyectData((prevState) => ({
                ...prevState,
                image: reader.result
            }));

        }
    }
    const onChangeFile = (e) => {
        const file = e.target.files[0];
        previewFile(file);
    }
    function EditProyectCard(props) {
        return <section className='edit-proyect'>

            <header>
                <h1> {props.title}</h1>
                <button type="submit" className="submit-btn font-primera" onClick={event => onDeleteProyect(event, String(props.id))}>
                    <FontAwesomeIcon icon={faTrashCan} > </FontAwesomeIcon><span> </span>
                    Delete
                </button>
                <button type="submit" className="submit-btn font-primera" onClick={e => onChangeEdit(e, props.index)} >
                    <FontAwesomeIcon icon={faPen} > </FontAwesomeIcon><span> </span>
                    Edit
                </button>
            </header>
            {toogleEdit.find(element => element.id === props.index).toogle &&

                <form onSubmit={event => onUpdateProyect(event, String(props.id))}
                    className={/*props.switch === ""*/true ? "contact-form row" : "contact-form row active"} id='form-add-proyect'
                    encType="multipart/form-data"
                >
                    <div className='form-group form-group-add-pryect'>
                        <div className="form-field col x-50 ">
                            <input type="title"
                                className={proyectData.title === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="title"
                                name='title'
                                defaultValue={props.title}
                                onChange={onChange}
                            >
                            </input>
                            <label className="label" >Title</label>
                        </div>
                        <div className="form-field col x-50">
                            <input type="text"
                                className={proyectData.position === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="position"
                                name='position'
                                defaultValue={props.position}
                                onChange={onChange}>
                            </input>
                            <label className="label" >Position</label>
                        </div>
                        <div className="form-field col x-50">
                            <input type="text"
                                className={proyectData.technology === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="input-technology"
                                name='technology'
                                defaultValue={props.technology}
                                onChange={onChange}>
                            </input>
                            <label className="label" >technology: a,b,c,t</label>
                        </div>

                        <div className="form-field col x-100">
                            <textarea
                                className={proyectData.description === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="description"
                                name="description"
                                defaultValue={props.description}
                                onChange={onChange}
                                rows="4"
                                cols="50">
                            </textarea>
                            <label className="label" >Description</label>
                        </div>
                        <div className="form-field col x-100">
                            <div className="form-field col x-10">
                                <label >Url</label>
                            </div>
                            <input type="url"
                                className={/*proyectData.url === "" */ true ? "input-text js-input" : "input-text js-input not-empty"}
                                id="url"
                                name='url'
                                defaultValue={props.url}
                                onChange={onChange}
                            >
                            </input>
                            <label className="label" >Proyect Url</label>
                        </div>
                        <div className="form-field col x-100">

                            <div className="form-field col x-10">
                                <label >Repository</label>
                            </div>
                            <label className="label" >Repository Url</label>
                            < input type="url"
                                className={/*proyectData.repository === ""*/true ? "input-text js-input" : "input-text js-input not-empty"}
                                id="repository"
                                name='repository'
                                defaultValue={props.repository}
                                onChange={onChange}
                            >
                            </input>

                        </div>
                        <div className="form-field col x-30">
                            <label >Date</label>
                            <input type="date"
                                className={proyectData.date === "" ? "input-text js-input" : "input-text js-input not-empty"}
                                id="date"
                                name='date'
                                defaultValue={props.date.split("T")[0]}
                                placeholder="Fecha"
                                onChange={onChange}
                            >
                            </input>
                        </div>
                        <div className="form-field col x-10">
                            <label >Experiment</label>
                            <input type="checkbox"
                                className='form-control'
                                id="experiment"
                                name='experiment'

                                onChange={onChangeCheckBox}
                                checked={toogle.current}

                            >
                            </input>
                        </div>
                        <div className="form-field col x-100">
                            <label >Imagen</label>
                            <input type="file"
                                accept="image/png, image/jpeg"
                                id="image"
                                name='image'
                                onChange={onChangeFile}
                                value={fileInputState}
                            >
                            </input>

                            <img
                                src={previewSource ? previewSource : props.image}
                                alt="chosen"
                                style={{ height: '300px' }}
                            ></img>

                        </div>
                        <div className='form-field col x-100 align-center'>
                            <button className="submit-btn" type="submit" defaultValue="Submit">Guardar</button>
                        </div>
                    </div>
                </form>
            }
            <div>
            </div>
        </section>;
    }



    useEffect(() => {

        return () => {
            dispatch(getProyects());
        };



    }, [dispatch])
    useEffect(() => {
        let arregloTemp = [];
        console.log(proyects);
        if (loadedProyects === true && proyects !== 'undefined') {
            proyects.forEach((proyect, index) => {

                const newToogle = {
                    toogle: false,
                    id: index
                }
                arregloTemp.push(newToogle);
                //settoogleEdit([...toogleEdit, newToogle])

            }
            );
            //console.log(arregloTemp);
            settoogleEdit(arregloTemp);
            console.log("Comida");
        }
    }, [proyects, loadedProyects, settoogleEdit])
    useEffect(() => {

        //dispatch(updateProyect(newProyectData))
        //dispatch(updateProyect(proyectData));
        // dispatch(updateProyect(proyectData));



        // cancel the subscription
        if (loadedProyects === true && proyectData.position !== "nan") {
            console.log("Data " + JSON.stringify(proyectData));
            console.log("On update proyect " + JSON.stringify(proyectData));
            dispatch(updateProyect(proyectData));
        }

    }, [loadedProyects, proyectData, dispatch])

    return (
        <>            {
            Array.isArray(proyects) && toogleEdit.length > 0 ? proyects.map((proyect, index) => (
                <EditProyectCard
                    key={proyect._id}
                    title={proyect.title}
                    id={proyect._id}
                    position={proyect.position}
                    date={proyect.date}
                    description={proyect.description}
                    repository={proyect.repository}
                    technology={proyect.technology}
                    image={proyect.image}
                    url={proyect.url}
                    experiment={proyect.experiment}
                    index={index}
                // switch={toogleEdit.find(element => element.id === index)}
                />
            ))
                : <h1>No loaded proyects</h1>
        }
        </>
    )
}

export default EditProyects