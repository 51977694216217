import React, { useRef, useEffect, useState } from 'react';
function AboutDetails(props, ref) {
    const [appearClass, setaAppearClass] = useState("appear");
    const containerRef = useRef();
    //let activeClass = false;
    const options = {
        threshold: 0,
        rootMargin: "0px 0px -200px 0px"
    };
    const observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // entry.target.classList.toggle("inverse");
                setaAppearClass("appear");

            } else {
                setaAppearClass("");

            }

        })
    }, options);
    useEffect(() => {
        if (containerRef.current) {
            observer.observe(containerRef.current);
            // <-- save ref value
        }
    }, []);
    return (
        <div id="aboutDetails" ref={ref}>
            <div id='container-details' >
                <h1 className={`left ${appearClass}`}>About Me</h1>
                <div ref={containerRef}>
                    <div className='text-section'>
                        <h2 className={`text-xs-big font-primera left ${appearClass}`}>Technology </h2>
                        <p className={`text-medium font-segunda right ${appearClass}`} >
                            Hi, my name is Raudel, i'm a free-lance Developer. Nice to meet you.
                            I have done video game and front-end/back-end web development.
                            I'am passionate about building my part of the web, that's why I like to learn various useful technologies, my favorite tools now are React and Node.js.

                        </p>
                    </div>
                    <div className='text-section'>
                        <h2 className={`text-xs-big font-primera  left ${appearClass} `}>Personal</h2>
                        <p className={`text-medium font-segunda right ${appearClass}`}>
                            I'am proud holder of a degree in computer systems engineer from the UPIIZ-IPN.
                            In my spare time I like to build my own projects, play video games and study Japanese and English.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(AboutDetails); 