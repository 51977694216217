import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import contactReducer from '../features/contact/contactSlice';
import proyectReducer from '../features/proyect/proyectSlice';
import voiceTextSlice from '../features/voiceText/voiceTextSlice';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    contact: contactReducer,
    proyect: proyectReducer,
    voiceText: voiceTextSlice
  },
});
