import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useState, useEffect, useRef } from 'react'
import { sendContact } from '../../features/contact/contactSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    const refIcon = useRef();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const { name, email, message } = formData;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const onChange = (e) => {
        if (formData.email === "" && formData.message === "" && formData.name === "") {
            refIcon.current.children[0].classList.remove('plane-animation-active');
            refIcon.current.classList.remove('active');
        }
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

    }
    const { user, isLoading, isError, isSuccess, messageContact } = useSelector(
        (state) => state.contact
    )
    const currenState = useSelector(state => state.contact)
    useEffect(() => {
        if (isError && isSuccess === false) {
            toast.error("message has not been sent ");
        }
        if (isLoading === true) {

            //  planeIcon.current.ClassName = "plane-animation-active";
        }
        if (isSuccess && isLoading === false) {
            toast.success("Message send");

            setFormData((prevState) => ({
                ...prevState,
                name: '',
                email: '',
                message: ''
            }));
        }

    }, [user, isError, isSuccess, messageContact, isLoading, navigate, dispatch])


    const onSubmit = (e) => {
        e.preventDefault()
        const contactData = {
            email,
            name,
            message
        }
        dispatch(sendContact(contactData))
        refIcon.current.children[0].classList.add('plane-animation-active');
        refIcon.current.classList.add('active');
    }
    //<FontAwesomeIcon icon={faCodepen}> </FontAwesomeIcon>
    // <input className="submit-btn font-primera" type="submit" value="Submit" >asd</input>
    return (
        <div className='component' id='contacto'>
            <section className="get-in-touch">
                <h1 className="title font-primera">Contact me</h1>
                <h2 className='font-primera'>Dont be shy</h2>
                <p className='font-segunda text-medium'>
                    Do you have a project in mind, do you want me to join your team, or just stop by to say hello
                </p>
                <form className="contact-form row" onSubmit={onSubmit} id='form-contact'>
                    <div className="form-field col x-50">
                        <input
                            id="contact-name"
                            name='name'
                            value={name}
                            className={formData.name === "" ? "input-text js-input" : "input-text js-input not-empty"}
                            type="text"
                            onChange={onChange}
                            required />
                        <label className="label font-primera" >Name</label>
                    </div>
                    <div className="form-field col x-50">
                        <input
                            className={formData.email === "" ? "input-text js-input" : "input-text js-input not-empty"}
                            type="email"
                            id="contact-email"
                            name='email'
                            value={email}
                            onChange={onChange}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            required />
                        <label className="label font-primera" >E-mail</label>
                    </div>
                    <div className="form-field col x-100">
                        <input
                            className={formData.message === "" ? "input-text js-input" : "input-text js-input not-empty"}
                            type="text"
                            onChange={onChange}
                            value={message}
                            name="message"
                            required />
                        <label className="label font-primera" >Message</label>
                    </div>


                    <button
                        ref={refIcon}
                        disabled={currenState.isLoading}
                        type="submit" className="submit-btn font-primera btn-hover" value="Submit" >
                        <FontAwesomeIcon
                            icon={faPaperPlane}
                        > </FontAwesomeIcon><span> </span>
                        Submit
                    </button>
                </form>
            </section>
        </div>
    )
}

export default Contact