
import React, { useRef } from 'react';
import Scrollspy from 'react-scrollspy'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../components/Spinner';
import PropTypes from 'prop-types';
function Header(props) {
    const { active } = props;
    let flag = useRef(false);
    let checkboxRef = useRef();
    let hamburger = useRef();
    const headerContainer = useRef();

    const [navLinksState, setNavLinksState] = useState({ home: '', proyects: '', contact: '', about: '' });
    const clearNavState = { home: 'nav-unSelected', proyects: 'nav-unSelected', contact: 'nav-unSelected' };
    const { user, isLoading, isError, isSuccess, proyects } = useSelector(
        (state) => state.proyect
    )
    useEffect(() => {
        if (!isLoading && !isSuccess && !flag.current) {
            flag.current = true;
        }
        //headerContainer.classList.toggle("nav-transparente");
    }, [user, isError, isSuccess, proyects, isLoading, active])
    if (isLoading) {
        return <Spinner />
    }
    const onClickNavLinks = (e) => {
        console.log("Click");
        const { name } = e.target.parentElement;
        setNavLinksState(prevState => ({
            ...clearNavState,
            [name]: 'nav-selected'
        }));
        e.target.parentElement.className = "nav-selected";
        checkboxRef.current.checked = false;
    }
    return (


        <div
            className={'nav ' + active} ref={headerContainer}>
            <input type="checkbox" id="nav-check" ref={checkboxRef}></input>
            <div className="nav-header">
                <div className="nav-title">
                    Raudel
                </div>
            </div>
            <div className="nav-btn">
                <label htmlFor="nav-check">
                    <span id='hamburger'></span>
                </label>
            </div>
            <div className="nav-links" id="nav-links">
                <Scrollspy
                    items={['about', 'aboutDetails', 'experiments', 'contacto']}
                    currentClassName="nav-selected"
                    scrolledPastClassName={'nav-unSelected'}
                >
                    <li name="home">
                        <a
                            href="#about"
                            className={navLinksState.home}
                            onClick={onClickNavLinks}
                        >
                            Home
                        </a>
                    </li>
                    <li name="about">
                        <a
                            href="#aboutDetails"
                            className={navLinksState.about}
                            onClick={onClickNavLinks}
                        >
                            About
                        </a>
                    </li>
                    <li name="proyects">
                        <a href="#experiments"
                            className={navLinksState.proyects}
                            onClick={onClickNavLinks}
                        >
                            Proyects
                        </a>
                    </li>
                    <li name="contact">
                        <a
                            href="#contacto"
                            onClick={onClickNavLinks}
                            className={navLinksState.contact}
                        >
                            contact
                        </a>
                    </li>
                </Scrollspy>
            </div>
        </div>


    )
}

Header.propTypes = {
    active: PropTypes.bool
}

export default Header