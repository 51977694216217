import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTwitter, faLinkedin, faCodepen } from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
        <div id='footer'>
            <section className='social'>
                <ul className="social-icons">
                    <li><a href='https://twitter.com/tstDeRau'><FontAwesomeIcon icon={faTwitter}> </FontAwesomeIcon></a></li>

                    <li><a href='https://github.com/raumunoz'><FontAwesomeIcon icon={faGithub}> </FontAwesomeIcon></a></li>
                    <li> <a href='https://codepen.io/raurau'><FontAwesomeIcon icon={faCodepen}> </FontAwesomeIcon></a></li>
                </ul>
                <span id="link-mail" align="right"><a href='mailto:raurau100@gmail.com'>raurau100@gmail.com</a></span>
            </section>
            <h4 id='copy-right'>Copyright © 2022 Raudel  All rights reserved.</h4>
        </div>
    )
}

export default Footer