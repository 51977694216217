import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'
// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    isError: false,
    isSucces: false,
    isLoading: false,
    haveUser: false,
    message: ''

}


export const register = createAsyncThunk('auth/register', async (user, thunkApi) => {
    try {
        return await authService.register(user)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})

export const login = createAsyncThunk('api/login', async (user, thunkApi) => {
    try {
        localStorage.removeItem('token');
        return await authService.login(user)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})
export const loadUser = createAsyncThunk('api/loadUser', async (token, thunkApi) => {

    try {
        return await authService.loadUser(token)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        localStorage.removeItem('token');
        return thunkApi.rejectWithValue(message)
    }
})
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(loadUser.pending, (state) => {
                state.isLoading = true
                state.haveUser = false
            })
            .addCase(loadUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.user = action.payload
                state.haveUser = true
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
                state.haveUser = false
            })
    },
})

export const { reset } = authSlice.actions

export default authSlice.reducer