
import PropTypes from 'prop-types'
function formatDate(dateString) {
    var date = new Date(dateString);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
}
function ContactItem(props) {
    const { name, email, message, date } = props;
    let formatedDate = formatDate(date);
    return (
        <li className='contact-item'>
            <section >
                <div className="contact-item-field">
                    <h4>{email}</h4>
                </div>
                <div className="contact-item-field">
                    <h1>{name} </h1><h4>{formatedDate}</h4>
                </div>
                <div className="contact-item-field">
                    <h2>{message}</h2>
                </div>
            </section>
        </li>
    )
}

ContactItem.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
    date: PropTypes.string
}

export default ContactItem
