import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loadUser } from '../features/auth/authSlice';
import ContactList from '../app/components/ContactList';
import Spinner from '../app/components/Spinner';
function Contacts() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let flag = useRef(false);
    const { user, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )
    useEffect(() => {
        if (isLoading) {
            <Spinner />
        }
        if (!localStorage.token) {
            navigate('/');
        }
        if ((localStorage.token && isLoading === false && flag.current === false)) {
            console.log("Cargando")
            dispatch(loadUser(localStorage.token));
            flag.current = true;
        }

        //dispatch(reset())
    }, [user, isError, isSuccess, isLoading, message, navigate, dispatch])
    return (
        <>
            {
                user ?
                    <ContactList />
                    :
                    <h1>...</h1>
            }
        </>
    )
}

export default Contacts