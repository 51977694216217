import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getProyects } from '../../features/proyect/proyectSlice';
import ProyectCard from './ProyectCard'
import Spinner from '../components/Spinner'

function Experiments() {

    //observer.observe(containerRef.current);

    /*
      const { containerRef, isVisible } = useElementOnScreen({
          root: null,
          rootMargin: "0px",
          threshold: 1.0
      });
  */
    const dispatch = useDispatch()
    let flag = useRef(false);
    const { user, isLoading, isError, isSuccess, proyects } = useSelector(
        (state) => state.proyect
    )
    useEffect(() => {

        if (!isLoading && !isSuccess && !flag.current) {
            flag.current = true;
            dispatch(getProyects())
        }
        if (isSuccess) {
            //String(proyect.technology).split(",").forEach(element => <label>{element}</label>)
            /*
            proyects.forEach(proyect => {
                const technologyArray = String(proyect.technology).split(",");
                proyect.list = technologyArray.map(item => {
                    <label>{item}</label>
                });
            });
            */
        }



    }, [user, isError, isSuccess, proyects, isLoading, dispatch])

    if (isLoading) {
        return <Spinner />
        //return <h1>Loading ...</h1>
    }
    return (
        <>
            <div className="experiment-header">
                <h1 className="text-xs-big">Experiments</h1>
                <p className="text-medium font-segunda">Check out some of the things I've build recently</p>
            </div>
            <div id='experiments' className='component' >

                {
                    proyects ?
                        proyects.map((proyect, index) => (
                            <ProyectCard
                                key={index}
                                position={proyect.position}
                                title={proyect.title}
                                description={proyect.description}
                                image={proyect.image}
                                url={proyect.url}
                                date={proyect.date}
                                technology={proyect.technology}
                                repository={proyect.repository}
                            />
                        ))
                        : <h1>No Proyects were found</h1>

                }
            </div>
        </>

    )
}

export default Experiments
