
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Contacts from './pages/Contacts';
import NotFound from './pages/NotFound';
import AddProyect from './pages/AddProyect';
import Speech from './pages/Speech';
import authService from '../src/features/auth/authService';

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
if (localStorage.token) {
  authService.setAuthToken(localStorage.token);
}

function App() {
  return (
    <>

      <Router>
        <div className='container'>
          <Routes>
            <Route path='/' element={<Dashboard />}></Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route path='/contacts' element={<Contacts />}></Route>
            <Route path='/proyects' element={<AddProyect />}></Route>
            <Route path='/speech' element={<Speech />}></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Routes>
        </div>
      </Router>

      <ToastContainer />
    </>

  );
}

export default App;
