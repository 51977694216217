import axios from 'axios'


const API_URL = process.env.REACT_APP_DOMAIN + '/api/users/'

const API_LOGIN = process.env.REACT_APP_DOMAIN + '/api/auth/'
const config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',

        'Content-Type': 'application/json'
    }
}

//Load user
const loadUser = async (token) => {
    axios.defaults.headers.common['x-auth-token'] = token;
    const res = await axios.get(API_LOGIN);
    return res.data
};
//Register user
const register = async (userData) => {
    const response = axios.post(API_URL, userData)

    if (response.data) {
        localStorage.setItem('token', JSON.stringify(response.data))
    }
    return response.data
}
//Login user
const login = async (userData) => {
    const response = await axios.post(API_LOGIN, userData, config);
    if (response.data) {
        axios.defaults.headers.common['x-auth-token'] = response.data;
        localStorage.setItem('token', response.data.token);
    }
    return response.data
}
const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['x-auth-token'] = token;
        localStorage.setItem('token', token);
    } else {
        delete axios.defaults.headers.common['x-auth-token'];
        localStorage.removeItem('token');
    }
}
const authService = {
    register,
    login,
    setAuthToken,
    loadUser

}
export default authService