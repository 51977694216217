import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import voiceTextService from './voiceTextService'

const initialState = {
    isError: false,
    isSucces: false,
    isLoading: false,
}

export const sendTextToVoice = createAsyncThunk('api/contact', async (text, thunkApi) => {
    try {
        return await voiceTextService.sendText(text)
    } catch (error) {
        // si existe alguino
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message)
    }
})


export const voiceTextSlice = createSlice({
    name: 'voiceText',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.response = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendTextToVoice.pending, (state) => {
                state.isLoading = true
            })
            .addCase(sendTextToVoice.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.response = action.payload
            })
            .addCase(sendTextToVoice.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.response = action.payload
            })
    },
})

export const { reset } = voiceTextSlice.actions
export default voiceTextSlice.reducer