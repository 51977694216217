import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getContacts } from '../../features/contact/contactSlice';
import { toast } from 'react-toastify'
import ContactItem from './ContactItem';

import Spinner from '../components/Spinner'
function ContactList() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let flag = useRef(false);

    const { user, isLoading, isError, isSuccess, contacts } = useSelector(
        (state) => state.contact
    )
    useEffect(() => {
        if (isError) {
            toast.error("contacts could not be obtained");
        }


        if (!isLoading && !isSuccess && !flag.current) {
            flag.current = true;
            dispatch(getContacts())
        }



    }, [user, isError, isSuccess, contacts, isLoading, navigate, dispatch])
    if (isLoading) {
        return <Spinner />
    }
    return (

        <div id='contactListDiv'>
            {contacts ?
                <ul id='catactList'>
                    {contacts.length > 0 && contacts.map((item, index) =>
                        <ContactItem
                            key={index}
                            name={item.name}
                            email={item.email}
                            message={item.message}
                            date={item.date}
                        />)
                    }
                </ul>
                :
                <h1> no hay Hay contactos</h1>}
        </div>

    )
}
//<ul>{contacts.length > 0 && contacts.map((item) => <li>{item} </li>)}</ul>
export default ContactList